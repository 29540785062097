import * as React from 'react';
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
// import styled from "@emotion/styled"
import Layout from '../../components/layout';
import Seo from '../../components/seo';

const IndexPageServices = () => (
    <Layout>
		<Seo title='Top1 Painting' description='List of Services' />

	</Layout>
);

export default IndexPageServices;
